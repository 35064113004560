import React from 'react'
import styled from 'styled-components'
import { Col } from 'react-flexbox-grid'
import { Link } from 'gatsby'
import { rhythm } from '../utils/typography'
import Image from 'gatsby-image'
import { BoxShadow } from '../const/styles'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import theme from '../const/theme'

const StyledLink = styled(AniLink)`
  display: block;
  text-align: center;
`

const ImageWrapper = styled.div`
  width: 100%;
`

const StyledImage = styled(Image)`
  width: 100%;
  ${BoxShadow}
`

const ArticleTitle = styled.p`
  max-width: 235px;
  margin-top: ${rhythm(0.5)};
  margin-bottom: ${rhythm(1.5)};
  margin-left: auto;
  margin-right: auto;
  font-size: smaller;
  color: ${theme.colors.gray_darker};
`

const ArticleCard = ({ node }) => {
  const title = node.frontmatter.title || node.fields.slug
  return (
    <Col xs={12} md={4} lg={3}>
      <StyledLink fade duration-={0.5} to={node.fields.slug}>
        <ImageWrapper className="image-wrapper">
          <StyledImage
            placeholderStyle={{filter: `blur(5px)`}}
            className="styled-image"
            fixed={node.frontmatter.thumbnail.childImageSharp.fixed}
          />
        </ImageWrapper>
        <ArticleTitle>{title}</ArticleTitle>
      </StyledLink>
    </Col>
  )
}

export default ArticleCard
