import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'
import { Grid, Row, Col } from "react-flexbox-grid"
import ArticleCard from '../components/articleCard'
import styled from "styled-components"
import {PageBound} from '../const/styles'

const StyledGrid = styled(Grid)`
  margin-top: 3rem;
  ${PageBound}
`
class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`Michelle Yuen`, `Product Designer`, `Amsterdam`, `UX/UI`]}
        />
        <StyledGrid fluid>
          <Row>
            {posts.map(({ node }) => {
              return(
                <ArticleCard key={node.fields.slug} node={node}/>
                )
            })}
          </Row>
        </StyledGrid>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }, filter: {frontmatter: {hidden: {ne: true}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            thumbnail {
              childImageSharp {
                fixed(width: 235, height: 235, cropFocus: CENTER) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
